import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";
import { BooksService } from "../services/books.service";
import { Book } from "../models/book";

declare var $: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public activatedAdvancedSearch = false;

  public books: Book[];

  public advancedSearch = {
    button: 'plus',
    search: 'Escriu aquí',
    search2: 'Autor',
  };

  public searchForm: FormGroup;

  public isSending: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private booksService: BooksService
  ) {
    this.books = this.route.snapshot.data.books;
    this.books.map(book => this.booksService.loadBookImages(book, true));
    this.isSending = false;
  }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.searchForm = new FormGroup({
      data: new FormControl(),
      title: new FormControl(),
      author: new FormControl(),
    });
  }

  getImage(book: Book) {
    return typeof book.firstImage === 'string' ? 'http://api.cbec.cat/assets/no-image.jpg' : 'http://admin.cbec.cat' + book.firstImage?.formats?.small.url;
  }

  activateAdvancedSearch() {
    this.activatedAdvancedSearch = !this.activatedAdvancedSearch;
    this.advancedSearch.button = this.activatedAdvancedSearch
      ? 'minus'
      : 'plus';
    this.advancedSearch.search = this.activatedAdvancedSearch
      ? 'Titol'
      : 'Escriu aquí...';
  }

  sendForm() {
    this.isSending = true;
    if (this.activatedAdvancedSearch) {
      delete this.searchForm.value.data;
      this.router.navigate(['/books/search/' + this.searchForm.value.title + '/' +
      this.searchForm.value.author + '/1']);
    } else {
      delete this.searchForm.value.title;
      delete this.searchForm.value.author;
      this.router.navigate(['/books/search-data/' + this.searchForm.value.data + '/1']);
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.sendForm();
    }
  }
}
