import { Injectable } from '@angular/core';
import { Book } from '../models/book';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BooksService {

  private MEDIA_URL = 'http://admin.cbec.cat/upload/files?';

  constructor(private httpClient: HttpClient) {
  }

  async loadBookImages(book: Book, firstImageOnly?: boolean) {
    if (firstImageOnly) {
      const bookImages = await this.httpClient.get(`${this.MEDIA_URL}name_in=${book.firstImage}`).toPromise();

      if (Object.keys(bookImages).length === 0) {
        console.log('fsdf');
        book.firstImage = 'http://api.cbec.cat/assets/no-image.jpg';
      } else {
        book.firstImage = bookImages[0];
      }
    } else {
      const query = book.images.join('&name_in=');
      const images = await this.httpClient.get(`${this.MEDIA_URL}name_in=${query}`).toPromise();

      if (Object.keys(images).length === 0) {
        book.images.map(image => 'http://api.cbec.cat/assets/no-image.jpg');
      } else {
        book.images = images;
        book.firstImage = images[0];
      }
    }

    return book;
  }
}
