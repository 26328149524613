import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiService } from '../services/api.service';
import { map } from 'rxjs/operators';
import { Book } from '../models/book';

@Injectable()
export class BookResolver implements Resolve<any> {
  constructor(private apiService: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    if (route.paramMap.get('id')) {
      return this.apiService.get('book/' + route.paramMap.get('id')).pipe(map(book => Book.create(book)));
    }

    if (route.paramMap.get('title')) {
      return this.apiService.get('books/' + route.paramMap.get('title') + '/' +
        route.paramMap.get('author') + '/' + route.paramMap.get('page'));
    }

    if (route.paramMap.get('data')) {
      return this.apiService.get('books-data/' + route.paramMap.get('data') + '/' +
        route.paramMap.get('page'));
    }

    if (route.paramMap.get('theme')) {
      return this.apiService.get('books-theme/' + route.paramMap.get('theme') + '/' + route.paramMap.get('page'));
    }

    return this.apiService.get('last-four-books').pipe(
      map(books => books.map(book => Book.create(book))));
  }
}
