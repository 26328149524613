import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Book } from "../models/book";
import { BooksService } from "../services/books.service";


@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.scss']
})
export class BooksComponent implements OnInit {

  public books: Book[];

  public total;

  public theme;

  public currentPage;

  public totalPages;

  public currentLink;

  public search;

  constructor(private route: ActivatedRoute,
              private sanitizer: DomSanitizer,
              private booksService: BooksService) {

    this.search = {
      data: null,
      title: null,
      author: null
    };
  }

  ngOnInit() {
    this.route.params.subscribe(routeParams => {
      this.books = this.route.snapshot.data.books['books'];
      this.total = this.route.snapshot.data.books['total'];
      this.theme = this.route.snapshot.params.theme;
      this.currentPage = routeParams.page;

      this.books.map(book => this.booksService.loadBookImages(book, true));

      if (routeParams.title) {
        this.search.title = routeParams.title;
        this.search.author = routeParams.author;
        this.currentLink = '/books/search/' + routeParams.title +
          '/' + routeParams.author + '/';
      } else if (this.theme) {

        this.currentLink = '/books/' + this.theme + '/';
      } else {
        this.search.data = routeParams.data;
        this.currentLink = '/books/search-data/' + routeParams.data + '/';
      }

      this.totalPages = Math.ceil(this.total / 12);
    });
  }

  getImage(book: Book) {
    return typeof book.firstImage === 'string' ? 'http://api.cbec.cat/assets/no-image.jpg' : 'http://admin.cbec.cat' + book.firstImage?.formats?.small.url;
  }

  getArray(n: number): any[] {
    return Array.from(Array(n).keys());
  }
}
