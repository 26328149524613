import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Book } from '../models/book';
import { BooksService } from "../services/books.service";

@Component({
  selector: 'app-books-view',
  templateUrl: './books-view.component.html',
  styleUrls: ['./books-view.component.scss']
})
export class BooksViewComponent implements OnInit {

  public book: Book;

  constructor(private route: ActivatedRoute,
              private booksService: BooksService) {
  }

  async ngOnInit() {
    this.book = this.route.snapshot.data.book;
    this.book = await this.booksService.loadBookImages(this.book);
  }

  getImage(book: Book) {
    return typeof book.firstImage === 'string' ? 'http://api.cbec.cat/assets/no-image.jpg' : 'http://admin.cbec.cat' + book.firstImage?.formats?.small.url;
  }
}
