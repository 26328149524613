const fields = ['id', 'zone', 'reference', 'code', 'title' , 'images', 'edition', 'year', 'language', 'author', 'publish',
  'editPlace', 'collection', 'volume', 'number', 'format', 'membership', 'theme', 'pages', 'illustrations', 'maps', 'currentPrice',
  'oldPrice', 'binding', 'editorial', 'state', 'measurements', 'catalogingDate', 'acquisitionDate', 'birthplace', 'ISBN',
  'legalDeposit', 'notes', 'notes2', 'new', 'library', 'location', 'firstImage'];

export class Book {

  id;

  zone;

  reference;

  code;

  title;

  images;

  firstImage;

  edition;

  year;

  language;

  author;

  publish;

  editPlace;

  collection;

  volume;

  number;

  format;

  membership;

  theme;

  pages;

  illustrations;

  maps;

  currentPrice;

  oldPrice;

  binding;

  editorial;

  state;

  measurements;

  catalogingDate;

  acquisitionDate;

  birthplace;

  ISBN;

  legalDeposit;

  notes;

  notes2;

  new;

  library;

  location;

  constructor(data) {
    fields.forEach((propertyName) => {
      if (data[propertyName] != null) {
        this[propertyName] = data[propertyName];
      } else if (data[propertyName] === null) {
        delete this[propertyName];
      }
    });
  }

  static create(data) {
    return new Book(data);
  }
}
