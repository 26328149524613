import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { BlogComponent } from './blog/blog.component';
import { WhoiamComponent } from './whoiam/whoiam.component';
import { BooksComponent } from './books/books.component';
import { BooksViewComponent } from './books-view/books-view.component';
import { BookResolver } from './resolvers/book.resolver';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    resolve: {books: BookResolver},
    data: {animation: 'isLeft'}
  },
  {path: 'contacte', component: ContactComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'qui-som', component: WhoiamComponent},
  {
    path: 'books/:theme/:page',
    component: BooksComponent,
    resolve: {books: BookResolver}
  },
  {
    path: 'books/search/:title/:author/:page',
    component: BooksComponent,
    resolve: {books: BookResolver},
    runGuardsAndResolvers: 'always',
    data: {animation: 'isRight'}
  },
  {
    path: 'books/search-data/:data/:page',
    component: BooksComponent,
    resolve: {books: BookResolver},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'book/:id',
    component: BooksViewComponent,
    resolve: {book: BookResolver},
    data: {animation: 'enter'}
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
