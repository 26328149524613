import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { WhoiamComponent } from './whoiam/whoiam.component';
import { BlogComponent } from './blog/blog.component';
import { BooksComponent } from './books/books.component';
import { BooksViewComponent } from './books-view/books-view.component';
import { BookResolver } from './resolvers/book.resolver';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
	declarations: [
		AppComponent,
		MenuComponent,
		HomeComponent,
		ContactComponent,
		FooterComponent,
		WhoiamComponent,
		BlogComponent,
		BooksComponent,
		BooksViewComponent,
		LoaderComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule
	],
	providers: [
		BookResolver
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
